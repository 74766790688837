import axios from 'axios';
import { API_BASE_PATH } from '../constants/constants';
import { getDefaultHeaders } from './helperMethods';
import errors from '../constants/errors';
import toast from 'react-hot-toast';


const client = axios.create({
    baseURL: API_BASE_PATH,
});

export const axiosInstance = async ({
    ...options
}) => {

    const onSuccess = (response) => {
        return response;
    };
    const onError = (error) => {
        if (error.response.status === 403) return error.response;
        return error;
    };
    let headers = getDefaultHeaders();

    client.defaults.headers = { ...headers, ...options.headers };
    delete options.headers;

    return client(options).then(onSuccess).catch(onError);
};

export const request = async ({
    ...options
}) => {
    let result = {
        success: false,
        data: {},
        error: ""
    }

    try {
        let res = await axiosInstance({ ...options });
        const { success, data, error } = res.data

        if (success) {
            result = {
                success: true,
                data,
                error: ""
            }
        } else {
            result = {
                success: false,
                data: {},
                error: error || errors.apiError
            }
        }
    } catch (error) {
        result = {
            success: false,
            data: {},
            error: errors.somethingWentWrong
        }
    }

    return result
};
