import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    contactNo: "",
    user: null,
    token: null,
    userExists: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setContactNo: (state, action) => {
            state.contactNo = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUserExists: (state, action) => {
            state.userExists = action.payload
        },
        setInitialState: (state) => {
            state.contactNo = initialState.contactNo
            state.user = initialState.user
            state.token = initialState.token
            state.userExists = initialState.userExists
        }
    },
})

export const authActions = authSlice.actions
export default authSlice.reducer