import { createSlice } from '@reduxjs/toolkit'
import { orderListingTabsEnum } from '../../../constants/constants'

export const orderViews = {
    userDetail: "userDetail",
    uploadPrescription: "uploadPrescription",
    orderPlaced: "orderPlaced",
    phoneInput: "phoneInput",
    otpInput: "otpInput"
}

const initialState = {
    currentView: orderViews.phoneInput,
    currentOrderHistoryView: orderListingTabsEnum.active,
    order: {
        patient: null,
        prescription: null,
    },
    recentlyAddedOrderId: ""
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setCurrentView: (state, action) => {
            state.currentView = action.payload
        },
        setCurrentOrderHistoryView: (state, action) => {
            state.currentOrderHistoryView = action.payload
        },
        setPatient: (state, action) => {
            state.order.patient = action.payload
        },
        setPrescription: (state, action) => {
            state.order.prescription = action.payload
        },
        setInitialState: (state) => {
            state.currentView = initialState.currentView
            state.order = initialState.order
        },
        setRecentlyAddedOrderId: (state, action) => {
            state.recentlyAddedOrderId = action.payload
        }
    },
})

export const orderActions = orderSlice.actions
export default orderSlice.reducer