import { createSlice } from '@reduxjs/toolkit'

export const sidebarNavigationViews = {
    main: "main",
    profile: "profile",
    orderHistory: "orderHistory"
}

const initialState = {
    open: false,
    currentView: sidebarNavigationViews.main
}

export const sidebarNavigationSlice = createSlice({
    name: 'sidebarNavigation',
    initialState,
    reducers: {
        open: (state) => {
            state.open = true
        },
        close: (state) => {
            state.open = false
        },
        setCurrentView: (state, action) => {
            state.currentView = action.payload
        }
    },
})

export const sidebarNavigationActions = sidebarNavigationSlice.actions
export default sidebarNavigationSlice.reducer