import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../store/hooks'
import { getAuthToken, getLoggedInUser, setSessionStorage } from '../utils/helperMethods'
import NavigationSidebar from '../components/global/navigationSidebar/NavigationSidebar'
import { authActions } from '../store/features/auth/authSlice'
import Loader from '../components/global/loader/Loader'
import 'core-js/full/promise/with-resolvers'
import { utmCampaignKey, utmSourceKey } from '../constants/constants'

const Layout = ({ children }) => {
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const token = getAuthToken()
        const user = getLoggedInUser()

        if (token && user) {
            dispatch(authActions.setToken(token))
            dispatch(authActions.setUser(user))
        }

        const searchParams = new URLSearchParams(document.location.search);
        const utm_source = searchParams.get('utm_source');
        const utm_campaign = searchParams.get('utm_campaign');

        if (utm_source) {
            setSessionStorage(utmSourceKey, utm_source)
        }

        if (utm_campaign) {
            setSessionStorage(utmCampaignKey, utm_campaign)
        }

        setLoading(false)
    }, [])

    return (
        <>
            {
                loading && <Loader />
            }
            {
                !loading && <>
                    <NavigationSidebar />
                    <div>{children}</div>
                </>
            }
        </>
    )
}

export default Layout