const errors = {
    invalidDOB: "Age must be at least 18 or more.",
    somethingWentWrong: "Something went wrong.",
    apiError: "Error from API.",
    noPrescription: "No prescription selected.",
    noPatient: "No patient selected.",
    invalidPincode: "Pincode is invalid.",
    invalidOrderId: "Order ID is invalid.",
    invalidRepeatOrderStartDate: "Order start date should be ahead of today",
    noOrderID: "No order ID!",
    noInvoiceFound: "No invoice found for this order!"
}

export default errors