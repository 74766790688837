const routeConstants = {
    whyUs: "WhyUs",
    howItWorks: "HowItWorks",
    blogs: "blogs",
    brandStory: "BrandStory",
    aboutUs: "AboutUs",
    home: "home",
    orderHistory: "/order/history"
}

const protectedRoutes = ["/order/history"]

export default routeConstants