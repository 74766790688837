import _ from 'lodash';
import { tokenKey, userKey } from '../constants/constants';
import Cookies from 'js-cookie'
import { moEngageEvents } from '../constants/moEngageEvents';
import toast from 'react-hot-toast';

var cookies = Cookies.withConverter({
    read: function (value, name) {
        if (name === tokenKey) {
            return value
        }
        return Cookies.converter.read(value, name)
    },
    write: function (value, name) {
        if (name === tokenKey) {
            return value
        } else {
            return value
        }
    }
})

const getDefaultHeaders = () => {
    return {
        Authorization: getAuthToken(),
        "Content-Type": "application/json",
        'app-version': "1000.0",
        "ngrok-skip-browser-warning": "69420"
    };
}

const getCookie = (name) => {
    const value = cookies.get(name)
    return value
}

const setCookie = (name, value, options = null) => {
    try {
        if (name === userKey) {
            value = _.pick(value, ['id', 'contact_no', 'dob', 'gender', 'mappingIds', 'name', 'country_code'])

            if (options?.isLogin) {
                window.Moengage.add_unique_user_id(value.id)
            } else {
                setUserMoengageData(value)
            }
        }

        cookies.set(name, JSON.stringify(value), { expires: options?.expiresIn || 365 });
    } catch (error) {
        console.log(error)
    }
}

const setUserMoengageData = (user) => {
    window.Moengage.add_user_attribute("name", user.name);
    window.Moengage.add_user_attribute('id', user.id);
    window.Moengage.add_gender(user.gender);
    window.Moengage.add_birthday(user.dob);
    window.Moengage.add_mobile(user.contact_no);
    window.Moengage.add_user_attribute('Mobile With Country Code Plus', `${user.country_code || ''}${user.contact_no}`);
    window.Moengage.add_user_attribute('Mobile With Country Code', `${user.country_code?.slice(1) || ''}${user.contact_no}`);
}

const removeCookie = (name) => {
    cookies.remove(name)
}

const setSessionStorage = (name, value) => {
    try {
        sessionStorage.setItem(name, JSON.stringify(value))
    } catch (error) {
        console.log(error)
    }
}

const getSessionStorage = (name) => {
    try {
        return JSON.parse(sessionStorage.getItem(name))
    } catch (error) {
        console.log(error)
    }
}

const getAuthToken = () => {
    const token = cookies.get(tokenKey)
    if (token) {
        return JSON.parse(token)
    }
    return null
}

const getLoggedInUser = () => {
    const user = getCookie(userKey)
    if (user) {
        return JSON.parse(user)
    }
    return null
}

const uppercaseToCapitalized = (string) => {
    if (string) {
        return string.charAt(0) + string.substring(1).toLowerCase();
    }

    return null
}

const getCapitalizedString = (string) => {
    if (string) {
        return string
            .split(' ')
            .map(word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ');
    }

    return null
}

const verifyPrescriptionfile = (file) => {
    if (!file) {
        return false
    }

    if (!file.type.includes("pdf") && !file.type.includes("png") && !file.type.includes("jpg") && !file.type.includes("jpeg")) {
        window.Moengage.track_event(moEngageEvents.INVALID_PRESCRIPTION_FILE_TYPE, {
            "file type": file.type,
            "file size": `${parseFloat((file.size / (1000 * 1000))).toFixed(2)} mb`,
        });
        toast.error("Invalid file type. You can only upload jpg, jpeg, png or pdf files.")
        return false
    }

    if (parseFloat((file.size / (1000 * 1000))) > 5) {
        window.Moengage.track_event(moEngageEvents.INVALID_PRESCRIPTION_FILE_SIZE, {
            "file type": file.type,
            "file size": `${parseFloat((file.size / (1000 * 1000))).toFixed(2)} mb`,
        });
        toast.error("File is too large. File should be of 2mb max.")
        return false
    }

    return true
}

export {
    getDefaultHeaders,
    getAuthToken,
    getLoggedInUser,
    getCookie,
    setCookie,
    removeCookie,
    uppercaseToCapitalized,
    getCapitalizedString,
    verifyPrescriptionfile,
    setSessionStorage,
    getSessionStorage
}