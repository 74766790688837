import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    startDate: "",
    order: {
        prescription: null,
        notes: ""
    },
    addressId: ""
}

export const repeatOrderSlice = createSlice({
    name: 'repeatorder',
    initialState,
    reducers: {
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setPatient: (state, action) => {
            state.order.patient = action.payload
        },
        setPrescription: (state, action) => {
            state.order.prescription = action.payload
        },
        setNotes: (state, action) => {
            state.order.notes = action.payload
        },
        setAddressId: (state, action) => {
            state.addressId = action.payload
        },
        setInitialState: (state) => {
            state.startDate = initialState.currentView
            state.order = initialState.order
            state.addressId = initialState.addressId
        }
    },
})

export const repeatOrderActions = repeatOrderSlice.actions
export default repeatOrderSlice.reducer