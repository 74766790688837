export const moEngageEvents = {
    HOMEPAGE_LANDING: "Homepage Landing",
    ORDER_NOW_CLICKED: "Order now Clicked",
    SIGNUP_LANDING_PAGE: "Signup landing page",
    RX_UPLOAD_LANDING_PAGE: "Rx upload landing page",
    PHONE_NUMBER_SUBMIT: "Phone Number Submit",
    OTP_GENERATED: "OTP Generated",
    OTP_VERIFIED: "OTP Verified",
    OTP_VERIFICATION_FAILED: "OTP Verification error",
    REGISTRATION_COMPLETED: "Registration Completed",
    UPLOAD_RX_CLICKED: "Upload RX Clicked",
    RX_UPLOADED: "RX uploaded",
    SELECT_USER_TYPE: "Select User Type",
    SELECT_GENDER: "Select Gender",
    SELECT_DOB: "Select DOB",
    SUBMIT_PRESCRIPTION: "Submit Prescription",
    ADDRESS_SUBMITTED: "Address Submitted",
    ADD_NEW_MEMBER: "New member added",
    INVALID_PRESCRIPTION_FILE_TYPE: "Invalid prescription file type",
    INVALID_PRESCRIPTION_FILE_SIZE: "Invalid prescription file size",
    REQUEST_CALLBACK_SUBMIT: "Request callback submit",
    REQUEST_CALLBACK_SUBMIT_SUCCESS: "Request callback submit sucess",
    REQUEST_CALLBACK_SUBMIT_ERROR: "Request callback submit error",
    CONSENT_LANDING_PAGE: "Consent Landing Page",
    ORDER_DETAILS_CONSENT_CONFIRMED: "Order Details Consent Confirmed",
    PACKAGING_CONSENT_CONFIRMED: "Packaging Consent Confirmed",
    ALL_CONSENTS_CONFIRMED: "All consents confirmed",
    ERROR_CONFIRMING_CONSENTS: "Error while confirming consents",
    PAYMENT_BUTTON_CLICKED: "Payment button clicked",
    REDIRECTED_TO_PAYMENT_PAGE: "User redirected to payment page",
    NO_PAYMENT_LINK_FOUND: "No payment link found",
    PAYMENT_CONFIRMED: "Payment Confirmed"
}