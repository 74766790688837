import React from 'react'
import images from '../../../constants/images'
import { ginger } from '../../../styles/fonts'

const Loader = () => {
    return (
        <div className='tw-p-4 tw-flex tw-flex-col tw-gap-3 tw-items-center tw-h-screen' >
            <img src={images.pillupLogo} className='tw-h-16 lg:tw-h-24 tw-object-contain' />
            <div className='tw-mt-6 tw-mb-[40px] lg:tw-max-w-[640px] lg:tw-mx-auto tw-flex tw-gap-3'>
                <img src={images.loader} className='tw-w-[18px] tw-object-contain' />
                <p className='tw-p-0 tw-m-0 tw-text-center tw-font-bold tw-text-[20px]' style={ginger.style} >Loading</p>
            </div>
        </div>
    )
}

export default Loader