import { configureStore } from '@reduxjs/toolkit'
import uiSlice from './features/ui/uiSlice'
import authSlice from './features/auth/authSlice'
import orderSlice from './features/order/orderSlice'
import sidebarNavigationSlice from './features/sidebarNavigation/sidebarNavigationSlice'
import consentSlice from './features/consent/consentSlice'
import repeatOrderSlice from './features/repeatOrder/repeatOrderSlice'

export const makeStore = () => {
    return configureStore({
        reducer: {
            ui: uiSlice,
            auth: authSlice,
            order: orderSlice,
            sidebarNavigation: sidebarNavigationSlice,
            consent: consentSlice,
            repeatOrder: repeatOrderSlice
        },
    })
}