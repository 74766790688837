import { createSlice } from '@reduxjs/toolkit'

export const consentViews = {
    schedule: "schedule",
    packaging: "packaging"
}

export const packagingTypeEnum = {
    blistered: "blistered",
    deblistered: "deblistered"
}

const initialState = {
    currentView: consentViews.phoneInput,
    order: null,
    packagingType: packagingTypeEnum.deblistered,
    acceptedSchedule: false,
    acceptedPackagingType: false
}

export const consentSlice = createSlice({
    name: 'consent',
    initialState,
    reducers: {
        setCurrentView: (state, action) => {
            state.currentView = action.payload
        },
        setOrder: (state, action) => {
            state.order = action.payload
        },
        setPackagingType: (state, action) => {
            state.packagingType = action.payload
        },
        setAcceptedSchedule: (state, action) => {
            state.acceptedSchedule = action.payload
        },
        setAcceptedPackagingType: (state, action) => {
            state.acceptedPackagingType = action.payload
        },
        setInitialState: (state, action) => {
            state.currentView = initialState.currentView
            state.order = initialState.order
            state.packagingType = initialState.packagingType
            state.acceptedSchedule = initialState.acceptedSchedule
            state.acceptedPackagingType = initialState.acceptedPackagingType
        }
    },
})

export const consentActions = consentSlice.actions
export default consentSlice.reducer