import React from 'react'
import MainNavigation from './MainNavigation'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { sidebarNavigationActions, sidebarNavigationViews } from '../../../store/features/sidebarNavigation/sidebarNavigationSlice'
import UserProfile from './UserProfile'

const NavigationSidebar = () => {
    const dispatch = useAppDispatch()
    const sidebarNavigationState = useAppSelector((state) => state.sidebarNavigation)
    const authState = useAppSelector((state) => state.auth)

    return authState.user && <>
        {sidebarNavigationState.open && <>
            <div onClick={() => dispatch(sidebarNavigationActions.close())} className='tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-[#0000001a] tw-z-20' />
            <div className={`tw-translate-all tw-duration-100 tw-ease-in-out tw-fixed tw-z-20 ${sidebarNavigationState.open && "tw-w-full lg:tw-w-[400px] tw-border-l-[1px] tw-border-solid tw-border-[#E2E2EA]"} ${!sidebarNavigationState.open && "tw-w-0"}  tw-h-screen tw-top-0 tw-right-0  tw-bg-white`} >
                <div className='tw-p-6 tw-h-full' >
                    {sidebarNavigationState.currentView === sidebarNavigationViews.main && <MainNavigation />}
                    {sidebarNavigationState.currentView === sidebarNavigationViews.profile && <UserProfile />}
                </div>
            </div>
        </>
        }
    </>
}

export default NavigationSidebar