import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Sheet } from 'react-modal-sheet';
import { ginger } from '../../../styles/fonts';

const relations = [
    "FATHER",
    "MOTHER",
    "SISTER",
    "BROTHER",
    "OTHER",
]

const RelationSelect = ({ relationship, onSelectRelation, disabled }) => {
    const [isOpen, setOpen] = useState(false);
    const [relationInputFieldBorder, setRelationInputFieldBorder] = useState("#E2E2EA")

    const handleSelectRelation = (relationItem) => {
        onSelectRelation(relationItem)
        setOpen(false)
    }

    useEffect(() => {
        if (relationship !== "" && relationInputFieldBorder !== "#1C8067") {
            setRelationInputFieldBorder("#1C8067")
        }

        if (relationship === "" && relationInputFieldBorder !== "#E2E2EA") {
            setRelationInputFieldBorder("#E2E2EA")
        }
    }, [relationship])

    return <div className='tw-flex tw-flex-col tw-gap-2' >
        <label className='tw-text-base tw-font-medium tw-text-[#424242]' style={ginger.style} >Select Relation</label>
        <button disabled={disabled} onClick={() => { setOpen(true) }} className={`tw-flex tw-justify-between tw-items-center tw-gap-2 tw-border tw-border-solid tw-rounded-2xl tw-bg-transparent tw-p-2 tw-border-[${relationInputFieldBorder}]`} >
            <span className='tw-text-base tw-font-semibold' style={ginger.style}>{relationship === "" ? "Select" : relationship.charAt(0) + relationship.substring(1).toLowerCase()}</span>
            <FontAwesomeIcon icon={faChevronDown} width={"12px"} color='#000000' size='lg' />
        </button>
        <Sheet isOpen={isOpen} onClose={() => setOpen(false)} detent={'content-height'} className='tw-max-w-[650px] tw-m-auto' >
            <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                    <div className='tw-px-4 tw-pb-7 tw-flex tw-flex-col tw-gap-3' >
                        <div className='tw-flex tw-justify-between tw-items-center' >
                            <span className=' tw-m-0 tw-p-0 tw-text-base tw-font-medium' style={ginger.style} >Select Relation</span>
                            <button onClick={() => setOpen(false)} className='tw-border-none tw-outline-none tw-bg-[#F1F1F5] tw-w-[20px] tw-h-[20px] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center' >
                                <FontAwesomeIcon icon={faXmark} width={"10px"} size='lg' color='#000000' />
                            </button>
                        </div>
                        <div className='tw-flex tw-flex-col tw-gap-2' >
                            {
                                relations.map((relationItem) => {
                                    return <button onClick={() => handleSelectRelation(relationItem)} className={`tw-border tw-border-solid tw-p-2 tw-flex tw-justify-between tw-rounded-2xl tw-outline-none ${relationItem === relationship ? "tw-bg-[#F3f8f7] tw-border-[#1C8067]" : "tw-bg-transparent tw-border-[#E2E2EA]"} `} key={relationItem}>
                                        <span className='tw-text-base tw-font-semibold' style={ginger.style} >{relationItem.charAt(0) + relationItem.substring(1).toLowerCase()}</span>
                                        <input className=' tw-accent-[#1C8067]' checked={relationship === relationItem} type='radio' />
                                    </button>
                                })
                            }
                        </div>
                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    </div>
}

export default RelationSelect