import React from 'react'
import images from '../../../constants/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { ginger } from '../../../styles/fonts'
import { removeCookie } from '../../../utils/helperMethods'
import { orderListingTabsEnum, tokenKey, userKey } from '../../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { authActions } from '../../../store/features/auth/authSlice'
import { sidebarNavigationActions, sidebarNavigationViews } from '../../../store/features/sidebarNavigation/sidebarNavigationSlice'
import { orderActions } from '../../../store/features/order/orderSlice'
import { useRouter } from 'next/router'
import routes from "../../../constants/routes"


const MainNavigation = () => {

    const router = useRouter()

    const dispatch = useAppDispatch()
    const authState = useAppSelector((state) => state.auth)

    const handleLogout = () => {
        removeCookie(tokenKey)
        removeCookie(userKey)
        dispatch(authActions.setInitialState())
        dispatch(orderActions.setInitialState())
        dispatch(sidebarNavigationActions.close())
        window.Moengage.destroy_session()
        router.replace("/order")
    }

    const handleRouteToOrderHistory = () => {
        dispatch(orderActions.setCurrentOrderHistoryView(orderListingTabsEnum.active))
        router.push(routes.orderHistory)
        dispatch(sidebarNavigationActions.close())
    }

    return (
        <div className='tw-flex tw-flex-col tw-gap-2 tw-items-stretch' >
            <div className='tw-flex tw-items-center tw-gap-5' >
                <img src={images.accountIcon} className='tw-w-[42px] tw-object-contain' />
                {
                    authState.user && <div className='tw-flex tw-flex-col tw-gap-0 tw-flex-1' >
                        <p className='tw-p-0 tw-m-0 tw-text-[20px] tw-font-semibold tw-tracking-[1px]' style={ginger.style} >{authState.user.name}</p>
                        <p className='tw-p-0 tw-m-0 tw-text-[12px] tw-font-medium tw-tracking-[1px]' style={ginger.style}>{`+91 ${authState.user.contact_no}`}</p>
                    </div>
                }
                <button onClick={() => dispatch(sidebarNavigationActions.close())} className='tw-border-none tw-outline-none tw-bg-[#F1F1F5] tw-w-[20px] tw-h-[20px] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center' >
                    <FontAwesomeIcon icon={faXmark} width={"10px"} size='lg' color='#000000' />
                </button>
            </div>
            <hr className='tw-w-full tw-border-[0.5px] tw-border-[#E2E2EA] tw-border-solid' />
            <button onClick={() => dispatch(sidebarNavigationActions.setCurrentView(sidebarNavigationViews.profile))} className='tw-outline-none tw-border-none tw-bg-transparent tw-w-full tw-flex tw-items-center tw-gap-5' >
                <img src={images.profileIcon} className='tw-w-[24px] tw-object-contain' />
                <p className='tw-text-base tw-text-left tw-font-medium tw-tracking-[1px] tw-flex-1 tw-text-[#000]' style={ginger.style} >My Profile</p>
                <div className='tw-flex tw-justify-center tw-items-center' >
                    <FontAwesomeIcon icon={faChevronRight} width={"10px"} size='lg' color='#000000' />
                </div>
            </button>
            <button onClick={handleRouteToOrderHistory} className='tw-outline-none tw-border-none tw-bg-transparent tw-w-full tw-flex tw-items-center tw-gap-5' >
                <img src={images.orderCart} className='tw-w-[24px] tw-object-contain' />
                <p className='tw-text-base tw-text-left tw-font-medium tw-tracking-[1px] tw-flex-1' style={ginger.style} >Order History</p>
                <div className='tw-flex tw-justify-center tw-items-center' >
                    <FontAwesomeIcon icon={faChevronRight} width={"10px"} size='lg' color='#000000' />
                </div>
            </button>
            <hr className='tw-w-full tw-border-[0.5px] tw-border-[#E2E2EA] tw-border-solid' />
            <button onClick={handleLogout} className='tw-outline-none tw-border-none tw-bg-transparent tw-w-full tw-flex tw-items-center tw-gap-5' >
                <img src={images.logoutIcon} className='tw-w-[24px] tw-object-contain' />
                <p className='tw-p-0 tw-m-0 tw-text-base tw-font-medium tw-tracking-[1px] tw-flex-1 tw-text-left tw-text-[#000]' style={ginger.style} >Logout</p>
            </button>
        </div>
    )
}

export default MainNavigation